import axios from 'axios'
import _ from 'lodash'

import { addTimesToDate, convertUnixToFormat, getDatesBetweenDates } from '../utils/dt'

export async function getOffers(key) {
	try {
		const url = `https://api.eflow.team/v1/networks/offerstable?page=1&page_size=1000`

		const options = {
			headers: {
				'x-eflow-api-key': key,
			},
		}

		const body = {
			filters: {
				offer_status: 'active',
			},
		}

		const response = await axios.post(url, body, options)

		const offers = response?.data?.offers?.map(offer => ({
			id: offer.network_offer_id + '',
			label: `${offer.name} (${offer.network_offer_id})`,
		}))

		const sorted = _.sortBy(offers, o => o.label.toLowerCase())

		return [{ id: '-1', label: 'ALL OFFERS' }, ...sorted]
	} catch (error) {
		console.error(error)
		throw error
	}
}

export async function getPartners(key) {
	try {
		const url = `https://api.eflow.team/v1/networks/affiliates?page=1&page_size=1000&filter=account_status=active`

		const options = {
			headers: {
				'x-eflow-api-key': key,
			},
		}

		const response = await axios(url, options)

		const affs = response?.data?.affiliates?.map(af => ({
			id: af.network_affiliate_id + '',
			label: af.name,
		}))

		const sorted = _.sortBy(affs, o => o.label.toLowerCase())

		return [{ id: '-1', label: 'ALL PARTNERS' }, ...sorted]
	} catch (error) {
		console.error(error)
		throw error
	}
}

export async function getClicks(key, filter) {
	try {
		if (!filter.from || !filter.to || !filter.offer || !filter.partner) {
			throw 'Invalid Input'
		}

		const clicks = []

		const dates = getDatesFilter(filter.from, filter.to)

		for (let i = 0; i < dates.length; i++) {
			const { from, to } = dates[i]
			const dateClicks = await getClicksAPI({ key, offerId: filter.offer, partnerId: filter.partner, from, to })
			clicks.push(...dateClicks)
		}

		return formatClicksResponse(clicks)
	} catch (error) {
		console.error(error)
		throw error
	}
}

async function getClicksAPI({ key, offerId, partnerId, from, to }) {
	const url = `https://api.eflow.team/v1/networks/reporting/clicks/stream`

	const options = {
		headers: {
			'x-eflow-api-key': key,
		},
	}

	const offerFilter = { resource_type: 'offer', filter_id_value: offerId }
	const partnerFilter = { resource_type: 'affiliate', filter_id_value: partnerId }

	const body = {
		from: from,
		to: to,
		timezone_id: 80,

		query: {
			filters: [...(offerId !== '-1' ? [offerFilter] : []), ...(partnerId !== '-1' ? [partnerFilter] : [])],
		},
	}

	const response = await axios.post(url, body, options)

	const clicks = response.data?.clicks
		?.map(c => ({
			transactionId: c.transaction_id,
			partnerId: c.relationship.affiliate.network_affiliate_id,
			offerId: c.relationship.offer.network_offer_id,
			hostname: c.relationship.query_parameters.hostname,
			pathname: c.relationship.query_parameters.pathname,
			unix: c.unix_timestamp,
			referer: c.referer || 'N/A',
		}))
		.map(c => ({
			transactionId: c.transactionId,
			partnerId: c.partnerId,
			offerId: c.offerId,
			url: c.hostname && c.pathname ? `https://${c.hostname}${c.pathname}` : c.referer,
			timestamp: convertUnixToFormat(c.unix),
		}))

	return clicks || []
}

function getDatesFilter(from, to) {
	const dates = getDatesBetweenDates(from, to)

	const all = []

	dates.forEach(d => all.push(...addTimesToDate(d)))

	return all
}

function formatClicksResponse(clicks) {
	const summary = _.groupBy(clicks, 'url')
	const result = Object.entries(summary)
	const mapped = result.map(i => ({ url: i[0], count: i[1]?.length }))
	const sorted = _.sortBy(mapped, o => o.count).reverse()

	const total = clicks.length
	return {
		summary: sorted,
		clicks: clicks,
		total: total,
	}
}

