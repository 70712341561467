const { DateTime } = require('luxon')

export const convertUnixToFormat = unix => {
	const dt = DateTime.fromSeconds(unix).setZone('America/New_York')
	return dt.toFormat('yyyy-MM-dd HH:mm:ss')
}

export const getDatesBetweenDates = (start, end) => {
	for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
		const [day] = new Date(dt).toISOString().split('T')
		arr.push(day)
	}

	return arr
}

export const addTimesToDate = date => {
	const res = []

	res.push({ from: `${date} 00:00:00`, to: `${date} 11:59:59` })
	res.push({ from: `${date} 12:00:00`, to: `${date} 23:59:59` })

	// for (let i = 0; i < 24; i++) {
	// 	const padded = pad(i)
	// 	res.push({ from: `${date} ${padded}:00:00`, to: `${date} ${padded}:59:59` })
	// }

	return res
}

const pad = int => {
	if (int <= 9) return `0${int}`
	else return int
}

