import { useEffect, useState, useContext } from 'react'
import { DateTime } from 'luxon'
import { JsonArray, download } from 'json-to-csv-in-browser'

import TextField from '@mui/material/TextField'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import LoadingButton from '@mui/lab/LoadingButton'

import DownloadIcon from '@mui/icons-material/Download'
import { Autocomplete, Button } from '@mui/material'

import KeysContext from '../context/keys/KeysContext'

import { getClicks, getOffers, getPartners } from '../services/everflow'
import SummaryTable from './SummaryTable'
import Loading from './Loading'
export default function Dashboard() {
	const { apiKey, loadKeys } = useContext(KeysContext)

	const [loading, setLoading] = useState()
	const [selectedStartDate, setSelectedStartDate] = useState(DateTime.now().minus({ days: 1 }))
	const [selectedEndDate, setSelectedEndDate] = useState(DateTime.now())

	const [selectedOffer, setSelectedOffer] = useState(null)
	const [selectedPartner, setSelectedPartner] = useState(null)
	const [noData, setNoData] = useState(false)

	const [offers, setOffers] = useState([])
	const [partners, setPartners] = useState([])
	const [clicksSummary, setClicksSummary] = useState([])
	const [clicksTotal, setClicksTotal] = useState(0)
	const [canDownload, setCanDownload] = useState(0)

	useEffect(() => {
		loadKeys()
	}, [])

	useEffect(() => {
		if (!apiKey) return

		loadDropdowns()
	}, [apiKey])

	const loadDropdowns = async () => {
		try {
			setLoading(true)
			const _offers = await getOffers(apiKey)
			const _partners = await getPartners(apiKey)

			setOffers(_offers)
			setPartners(_partners)
			setLoading(false)
		} catch (err) {
			console.error(err.message)
		}
	}

	useEffect(() => {
		const invalid = !selectedStartDate || !selectedEndDate || !selectedOffer || !selectedPartner

		setCanDownload(!invalid)
	}, [selectedStartDate, selectedEndDate, selectedOffer, selectedPartner])

	const downloadData = async () => {
		try {
			setLoading(true)

			const filter = {
				from: selectedStartDate?.toISODate(),
				to: selectedEndDate?.toISODate(),
				offer: selectedOffer?.id,
				partner: selectedPartner?.id,
			}

			setClicksSummary([])
			setClicksTotal(0)
			setNoData(false)

			const { summary, clicks, total } = await getClicks(apiKey, filter)

			if (clicks.length) {
				setClicksSummary(summary)
				setClicksTotal(total)

				const jsonArray = new JsonArray(clicks)
				const str = jsonArray.convertToCSVstring()
				download('clicks-report.csv', str)
			} else {
				setNoData(true)
			}
		} catch (error) {
			console.error(error)
			alert('Unexpected Error')
		} finally {
			setLoading(false)
		}
	}

	return (
		<div>
			<div className="filter-row">
				<DesktopDatePicker
					margin="normal"
					label="From"
					inputFormat="MM/dd/yyyy"
					value={selectedStartDate}
					onChange={setSelectedStartDate}
					renderInput={params => <TextField className="control min-width margin" {...params} />}
				/>

				<DesktopDatePicker
					margin="normal"
					label="To"
					inputFormat="MM/dd/yyyy"
					value={selectedEndDate}
					onChange={setSelectedEndDate}
					renderInput={params => <TextField className="control min-width margin" {...params} />}
				/>

				<Autocomplete
					disablePortal
					options={partners}
					margin="normal"
					value={selectedPartner}
					onChange={(e, val) => setSelectedPartner(val)}
					renderInput={params => <TextField className="control min-width margin" {...params} label="Partner" style={{ width: '350px' }} />}
				/>

				<Autocomplete
					disablePortal
					options={offers}
					margin="normal"
					value={selectedOffer}
					onChange={(e, val) => setSelectedOffer(val)}
					renderInput={params => <TextField className="control min-width margin" {...params} label="Offer" style={{ width: '350px' }} />}
				/>

				<Button variant="contained" className="control margin" disabled={!canDownload} onClick={downloadData} startIcon={<DownloadIcon />}>
					Download Clicks
				</Button>
			</div>
			{<Loading loading={loading} />}

			{noData && <p>No data found.</p>}
			<SummaryTable rows={clicksSummary} total={clicksTotal} />
		</div>
	)
}
